<template>
  <div>
    <div class="container max-w-screen-lg item-center mx-auto" id="news" :key="detailEvent.id_event">
      <div class="py-8 px-16">
        <div class="flex flex-wrap items-center">
          <div class="w-full sm:w-1/2">
            <img :src="detailEvent.foto_event" class="w-full" alt="" />
          </div>
          <div class="w-full sm:w-1/2 flex items-center">
            <div class="pl-0 sm:pl-8 font-medium w-full sm:mt-0 mt-5">
              <p>Event Name</p>
              <h2 class="text-2xl font-semibold">{{detailEvent.judul_event}}</h2>
              <p class="mt-3">Date</p>
              <p><span class="mr-2">
                  <font-awesome-icon icon="fa-regular fa-calendar" size="lg" />
                </span>{{$moment(detailEvent.tanggal_event).format("dddd, D MMMM YYYY")}}</p>
              <p><span class="mr-2">
                  <font-awesome-icon icon="fa-regular fa-clock" size="lg" />
                </span>{{detailEvent.jam_event}} WIB</p>
              <p class="mt-3">Location</p>
              <p><span class="mr-2">
                  <font-awesome-icon icon="fa-solid fa-location" size="lg" />
                </span>{{detailEvent.alamat_event}}</p>
              <div class="flex mt-6">
                <button class="text-white py-2 px-12 rounded-full bg-primary hover:bg-primary-darker"
                  @click="checkRegister(detailEvent.id_event)">Join Event</button>
              </div>
            </div>
          </div>
        </div>
        <p class="mt-8 text-lg text-justify">{{detailEvent.deskripsi_event}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventList',
  components: {
  },
  data: () => ({
    detailEvent: {},
    check: null,
  }),
  beforeRouteEnter(to, from, next) {
    if (to.params.id === undefined || to.params.id === 0) {
      next({ name: 'EventList' });
    } else {
      next();
    }
  },
  methods: {
    async checkRegister(id) {
      if (this.detailEvent.khusus_linke === 1) {
        window.open(this.detailEvent.linke, '_blank')
        return false
      }
      const checkUser = this.$store.state.user.telepon
      if (!checkUser) {
        this.$router.push({ name: "Login" })
        return false
      }
      try {
        const res = await this.$http.post(`${this.$apiTripweWeb}/event/check-register`, {
          txtTelepon: this.$store.state.user.telepon,
          txtIdEvent: id
        })
        if (res.data.status === 200) {
          this.$toasted.global.success("You are already registered");
        } else {
          this.$router.push({ name: "EventRegistration" });
        }
      } catch (error) {
        this.$toasted.global.error(error);
      }
    }
  },
  async created() {
    this.$store.commit('setOverlayLoading', true);
    try {
      const id = this.$route.params.id;
      const res = await this.$http.get(`${this.$apiTripweWeb}/event/${id}/detail`)
      this.detailEvent = res.data.response
      this.check = res.data.response.alamat_event
    } catch (error) {
      this.$toasted.global.error("Failed to load event");
      this.$router.push({ name: "EventList" });
    } finally {
      this.$store.commit('setOverlayLoading', false);
    }
  },
};
</script>
